.MainForm-container {
    display:         flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    
    margin: 20px;
    flex-direction: column; /* 子要素をflexboxにより縦方向に揃える */
    justify-content: flex-start; /* 子要素をflexboxにより横中央に配置する */
    align-items: stretch;  /* 子要素をflexboxにより縦中央に配置する */
     
    font-size: calc(10px + 2vmin);
  }
  

  .MainForm-content {
    width: 100%;
    height: 100%;
  }

  .Text-Center
  {
    text-align: center;
  }

  .Cell
  {
    background-color: white;
  }

  .Cell-Diff
  {
    background-color: aliceblue;
  }

  .MainForm-table {

  }
  