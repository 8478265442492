.App {
  text-align: center;
  display: -webkit-flex;
  display:    -moz-flex;
  display:     -ms-flex;
  display:         flex;

  width: 100vw;
  height: 100vh;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-login {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body{
  display: flex;

}

.btn-flat {
  background-color: purple;
  color: white;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}
