.Initial 
{
    flex-direction: column; /* 子要素をflexboxにより縦方向に揃える */
    justify-content: center; /* 子要素をflexboxにより横中央に配置する */
    align-items: center;  /* 子要素をflexboxにより縦中央に配置する */
     
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-box-flex: 1;
    box-flex: 1;
}